import { useEffect, useState } from "react";
import "./qrcode.css"
import { getOrderPrint } from "./Helper/order";
import { QrReader } from "react-qr-reader";

const QrCode = () => {
    const [qrData, setQrData] = useState(null);
    const [data, setData] = useState(null)
    const [isLoading, setIsLoading] = useState(true);

    const getOrderData = async () => {
        setIsLoading(true)
        try {
            const res = await getOrderPrint(qrData);
            setData(res?.data)
            setIsLoading(false)
        }
        catch (error) {
            console.log("error:", error)
            setIsLoading(false)
        }
    }


    useEffect(() => {
        if (qrData !== null) {
            getOrderData()
        }
    }, [qrData])

    return <>
        <div className="p-2 p-md-3">
            <div className="p-5 bg-white shadow-lg" id="order-invoice">
                {qrData === null && <div className="px-5 text-center" >
                    <div style={{ maxWidth: "300px", margin: "0 auto" }}>
                        <QrReader
                            constraints={{ facingMode: 'environment' }}
                            onResult={(result, error) => {
                                if (!!result) {
                                    setQrData(result?.text)
                                }

                                if (!!error) {
                                    console.info(error);
                                }
                            }}
                            style={{ width: '100%' }}
                        />
                    </div>
                </div>}
                {data !== null && <>
                    <div className="d-flex justify-content-center mb-4 pb-4 border-bottom">
                        <h3>INVOICE</h3>
                    </div>
                    <div className="d-flex flex-column flex-md-row justify-content-between px-4">
                        <p className="customer-details" style={{ color: "gray", fontWeight: 600 }}>Customer Name :<span style={{ color: "black", fontWeight: 600 }}> {data?.customerName}</span></p>
                        <p className="order-date" style={{ color: "gray", fontWeight: 600 }} >Order Date : <span style={{ color: "black", fontWeight: 600 }}> {data?.orderDate}</span></p>
                    </div>
                    <div className="px-4">
                        <p><strong>Items:</strong> </p>
                        <div className="" id="table_div">
                            <table className="w-100 border-1">
                                <thead>
                                    <tr>
                                        <th className="text-center p-2">SR NO.</th>
                                        <th className="text-center p-2">ITEM</th>
                                        <th className="text-center p-2">TYPE</th>
                                        <th className="text-center p-2">IS COMPLETE</th>
                                        <th className="text-center p-2">IS SENT</th>
                                        <th className="text-center p-2">IS RECEIVED</th>
                                        <th className="text-center p-2">CHARGES</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        data?.orderItems?.length > 0 && data?.orderItems?.map((el, i) => {
                                            return <tr>
                                                <td className="text-center p-2">{i + 1}</td>
                                                <td className="text-center p-2">{el?.item}</td>
                                                <td className="text-center p-2">{el?.type}</td>
                                                <td className="text-center p-2">{el?.isComplete == 1 ? "Yes" : "No"}</td>
                                                <td className="text-center p-2">{el?.isSent == 1 ? "Yes" : "No"}</td>
                                                <td className="text-center p-2">{el?.isReceived == 1 ? "Yes" : "No"}</td>
                                                <td className="text-center p-2">{el?.charges} RS</td>
                                            </tr>
                                        })
                                    }
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td className="text-center p-2 border-0"></td>
                                        <td className="text-center p-2 border-0"></td>
                                        <td className="text-center p-2"></td>
                                        <td className="text-center p-2"></td>
                                        <td className="text-center p-2"></td>
                                        <td className="text-center p-2 total-header">TOTAL</td>
                                        <td className="text-center total-border">{data?.totalCharges} RS</td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                    {data?.note != "" && <div className="px-4 mt-4">
                        <p><strong>Note:</strong> {data?.note}</p>
                    </div>}
                    <div className="d-flex justify-content-end px-4">
                        <button className="btn btn-primary" onClick={() => { setQrData(null); setData(null) }}>Scan Again</button>
                    </div>
                </>}
            </div>
        </div>
    </>
}

export default QrCode;