import React from 'react'
import CIcon from '@coreui/icons-react';
import {
  cilBell,
  cilCalculator,
  cilChartPie,
  cilCursor,
  cilDescription,
  cilDrop,
  cilNotes,
  cilPencil,
  cilPuzzle,
  cilSpeedometer,
  cilStar,
  cilCalendar,
  cilCalendarAlt,
  cilList,
  cilShieldAlt,
  cilAvTimer,
  cilJustifyCenter,
  cilLineStyle
} from '@coreui/icons'
import { CNavGroup, CNavItem, CNavTitle } from '@coreui/react'

const _nav = [
  {
    component: CNavItem,
    name: 'Dashboard',
    to: '/dashboard',
    icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,

  },
  {
    component: CNavItem,
    name: 'Customer',
    to: '/customer',
    icon: <CIcon icon={cilNotes} customClassName="nav-icon" />,

  },
  {
    component: CNavItem,
    name: 'Customer Orders',
    to: '/customerbilling',
    icon: <CIcon icon={cilCalculator} customClassName="nav-icon" />,

  },

  {
    component: CNavItem,
    name: 'Outstanding',
    to: '/totaloutstanding',
    icon: <CIcon icon={cilList} customClassName="nav-icon" />,

  },
  {
    component: CNavItem,
    name: 'Last Week Orders',
    to: '/lastweekorders',
    icon: <CIcon icon={cilCalendar} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: "Yesterday's Orders",
    to: '/yesterdaysorders',
    icon: <CIcon icon={cilShieldAlt} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: "Received List",
    to: '/receivedlist',
    icon: <CIcon icon={cilJustifyCenter} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: "Item's Not Picked",
    to: '/itemnotpicked',
    icon: <CIcon icon={cilAvTimer} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: "Scan QR Code",
    to: '/qrscan',
    icon: <CIcon icon={cilLineStyle} customClassName="nav-icon" />,
  },
]

export default _nav
