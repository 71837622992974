import { useState, useEffect, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Component } from "react";
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CForm,
  CFormCheck,
  CFormInput,
  CFormFeedback,
  CFormLabel,
  CFormSelect,
  CFormTextarea,
  CInputGroup,
  CInputGroupText,
  CWidgetStatsF,
  CWidgetStatsB,
  CRow,
  CHeaderDivider,
  CBreadcrumb,
  CBreadcrumbItem,
  CContainer,
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CDropdown,
  CDropdownToggle,
  CDropdownMenu,
  CDropdownItem,
} from "@coreui/react";
import "../Customerbilling/customerbilling.css";
import DataTable, { FilterComponent } from "react-data-table-component";
import CIcon from "@coreui/icons-react";
import {
  cilFile,
  cilFolderOpen,
  cilInfo,
  cilLibraryAdd,
  cilPencil,
  cilSettings,
  cilTrash,
} from "@coreui/icons";
import {
  fetchCustomerListApi,
  orderListApi,
  OrderPhotoUpdate,
  OrderSendSms,
  deleteorderByIdApi,
  fetchCustomerListApi1,
} from "../Helper/order";
import swal from "sweetalert";
import Select from "react-select";

const customStyles = {
  cells: {
    style: {
      borderRight: "1px solid #ddd",
    },
  },
};

const Yesterdaysorders = () => {
  const [data, setData] = useState();
  const [filterStartDate, setfilterStartDate] = useState("");
  const [filterEndDate, setfilterEndDate] = useState("");
  const [orderList, setorderList] = useState([]);
  const [filterdata, setfilterdata] = useState(orderList);
  const [perPage, setPerPage] = useState(10);
  const [editOrderId, seteditOrderId] = useState(0);
  const [orderTotalRecord, setorderTotalRecord] = useState();
  const [filterOnCustomer, setfilterOnCustomer] = useState("");
  const [filterOnbillNo, setfilterOnbillNo] = useState();
  const [listLoading, setListLoading] = useState(false);
  const [customerList, setCustomerList] = useState();
  const [selectedCustomer, setSelectedCustomer] = useState("");
  const [selectedSentType, setSelectedSentType] = useState("");
  const [visible, setVisible] = useState(false);
  const [imageError, setImageError] = useState("");
  const [image, setImage] = useState();
  const [preview, setPreview] = useState();
  const [smsSendFlag, setsmsSendFlag] = useState();
  // const [isBillPaid, setisBillPaid] = useState();
  const [orderPhoto, setOrderPhoto] = useState("");
  const [inputValue, setInputvalue] = useState("");
  const [orderIdForPhoto, setOrderIdForPhoto] = useState();
  const userData = useSelector((state) => state.userData);
  let navigate = useNavigate();
  let userId = userData.userinfo.userId;
  useEffect(() => {
    if (orderList.length == 0) {
      getorderlist(0);
      if (sessionStorage.getItem("selectedCustomer-yesterday") !== null) {
        setSelectedCustomer(sessionStorage.getItem("selectedCustomer-yesterday"))
      } else {
        setSelectedCustomer("");
      }
      if (sessionStorage.getItem("selectedSentType-yesterday") !== null) {
        setSelectedSentType(sessionStorage.getItem("selectedSentType-yesterday"))
      } else {
        setSelectedSentType("");
      }
    }
  }, []);

  useEffect(() => {
    if (inputValue !== "") {
      setTimeout(() => {
        getCutomerlist();
      }, 200);
    }
  }, [inputValue])

  useEffect(() => {
    getCutomerlist()
  }, [])

  const sentOptions = [{ value: "0", label: "Not Sent" }, { value: "1", label: "Sent" }];

  const getCutomerlist = () => {
    fetchCustomerListApi1(inputValue).then((res) => {
      var result = res?.data;
      var customerLive = [];
      result.forEach((element) => {
        customerLive.push({
          value: element.customerId,
          label: element.customerName,
        });
      });
      setCustomerList(customerLive);
    });

  };

  const handleCustomer = (e) => {
    setSelectedCustomer(e.value);
    sessionStorage.setItem("selectedCustomer-yesterday", e.value);

  };

  const handleInputChange = (value) => {
    setInputvalue(value);
  }

  const handleSent = (e) => {
    //alert(e.value)
    setSelectedSentType(e.value);
    sessionStorage.setItem("selectedSentType-yesterday", e.value);
  };

  const paginationComponentOptions = {
    rowsPerPageText: "",
    noRowsPerPage: true,
  };

  const getorderlist = async (page) => {
    // if(userData.userinfo.userId){
    // console.log("sart", filterStartDate)
    // console.log("end", filterEndDate)
    // console.log("Customer", filterOnCustomer)



    let currentDate = new Date();
    let endformattedDate = currentDate.toLocaleDateString("es-CL", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
    let startDate = new Date(currentDate);
    startDate.setDate(currentDate.getDate() - 1);
    let startformattedDate = startDate.toLocaleDateString("es-CL", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
    setfilterStartDate(startformattedDate)
    // setfilterEndDate(endformattedDate)
    // console.log(startformattedDate, endformattedDate);

    setListLoading(true);
    let customerId;
    if (sessionStorage.getItem("selectedCustomer-yesterday") !== null) {
      customerId = sessionStorage.getItem("selectedCustomer-yesterday")
    } else {
      customerId = selectedCustomer
    }
    if (sessionStorage.getItem("selectedSentType-yesterday") !== null) {
      var sentType = sessionStorage.getItem("selectedSentType-yesterday")
    } else {
      sentType = selectedSentType
    }


    await orderListApi(
      userData.userinfo.userId,
      page ? page : 0,
      startformattedDate,
      startformattedDate,
      customerId,
      sentType
    )
      .then(
        async (res) => {
          setorderList(res.data);
          setsmsSendFlag(res.data.smsSendFlag);
          setData(res.data);
          setListLoading(false);
          setorderTotalRecord(res.totalRecords);
        },
        (err) => {
          setListLoading(false);
        }
      )
      .catch(setListLoading(false));
  };

  const handlePageChange = (page) => {
    let currentPage = page;
    var offset = (currentPage - 1) * perPage;
    getorderlist(offset);
  };

  useEffect(() => {
    if (!image) {
      setPreview(undefined);
      return;
    }
    const objectUrl = URL.createObjectURL(image);
    if (image.type === "image/png" || image.type === "image/jpeg") {
      // console.log("ok")
      setPreview(objectUrl);
    } else {
      // console.log("notok");
      setPreview(null);
    }

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [image]);

  const handleFileChangeImage = (e) => {
    // console.log(e.target.files[0]);
    if (e.target.files) {
      if (
        e.target.files[0].type == "image/png" ||
        e.target.files[0].type == "image/jpeg"
      ) {
        setImageError("");
      } else {
        setImageError("Please select Image Type of png or jpg");
      }
      setImage(e.target.files[0]);
    }
  };

  const deleteorder = async (orderId) => {
    let payloadData = {
      // userId: userData.userinfo.userId,
      orderId: orderId,
    };

    swal({
      title: "Are you sure?",
      text: "Are you sure to delete Order?",
      icon: "warning",
      dangerMode: true,
      buttons: ["Cancel", "Ok"],
    }).then((willDelete) => {
      if (willDelete) {
        deleteorderByIdApi(payloadData).then((res) => {
          if (res.status === 200) {
            swal("Success", res.message, "success").then((ok) => {
              if (ok) {
                window.location.reload();
              }
            });
          }
        });
      }
    });
  };

  const handleModal = (oid) => {
    setVisible(true);
    setOrderIdForPhoto(oid);
  };

  const handleSMSFlag = async (orderId) => {
    // console.log(orderId);
    let payloadData = {
      userId: userId,
      orderId: orderId,
    };
    await OrderSendSms(payloadData).then((res) => {
      if (res.status === 200) {
        swal("Success", res.message, "success").then((ok) => {
          if (ok) {
            window.location.reload();
          }
        });
      } else {
        swal("Warning", res.message, "warning");
      }
    });
  };

  const uploadPhoto = (e) => {
    let formData = new FormData();
    formData.append("orderId", orderIdForPhoto);
    formData.append("orderPhoto", image);
    OrderPhotoUpdate(formData).then((res) => {
      if (res.status === 200) {
        swal("Success", res.message, "success").then((ok) => {
          if (ok) {
            window.location.reload();
          }
        });
      } else {
        swal("Warning", res.message, "warning");
      }
    });
  };

  const handleCustomerOutstanding = (orderId, orderDate, customerId) => {
    // console.log(orderId, orderDate, customerId); 
  };

  const columns = [
    {
      name: " No",
      selector: (row, index) => index + 1,
      width: "50px"
    },
    {
      name: "Actions",
      allowOverflow: true,
      wrap: true,
      width: "150px",
      selector: (row) => (
        <>
          <div className="button-customerorder">

            <CButton
              color="success"
              variant="outline"
              title="Add Outstanding"
              style={{ marginRight: '8px', marginLeft: '0px !important' }}
              className="ms-2 buttonsOrderPage w-100"
              onClick={() =>
                navigate("/customerbilling/actionoutstanding", {
                  state: {
                    orderId: row.orderId,
                    orderDate: row.orderDate,
                    customerId: row.customerId,
                    customerName: row.customerName,
                  },
                })
              }
            >
              <CIcon icon={cilLibraryAdd} size="lg" />
            </CButton>

            <CDropdown variant="btn-group" direction="dropup-center" >
              <CDropdownToggle color="dark" className="btn-md"> <CIcon icon={cilSettings} size="lg" /></CDropdownToggle>
              <CDropdownMenu className="topdropshow">
                {
                  row.isBillPaid === "1" ? (<></>) : (<>
                    <Link to={"/order/" + row.orderId} className=" text-decoration-none" >
                      <CDropdownItem >
                        <CButton
                          color="dark"
                          title="Edit Order"
                          variant="outline"
                          className="px-0 buttonsOrderPage "
                        >
                          <CIcon icon={cilPencil} size="lg" className="" />
                        </CButton>
                        <span className="ms-3 text-decoration-none text-dark" >Edit </span>
                      </CDropdownItem>
                    </Link>
                  </>)
                }

                <Link to={"/view/" + row.orderId} className=" text-decoration-none">
                  <CDropdownItem>
                    <CButton
                      color="secondary"
                      title="View Order"
                      variant="outline"
                      className="px-0 buttonsOrderPage "
                    >
                      <CIcon icon={cilFolderOpen} size="lg" />
                    </CButton>
                    <span className="ms-3 text-decoration-none text-dark" >View</span>


                  </CDropdownItem>
                </Link>

                <Link to={"https://cplasersoldering.cplaser.in/opr/" + row.orderId} className=" text-decoration-none" target="_blank" >
                  <CDropdownItem >
                    <CButton
                      color="dark"
                      title="Print"
                      variant="outline"
                      className="px-0 buttonsOrderPage "
                    >
                      <CIcon icon={cilFile} size="lg" className="" />
                    </CButton>
                    <span className="ms-3 text-decoration-none text-dark" >Print</span>
                  </CDropdownItem>
                </Link>

                <CDropdownItem href="#" onClick={() => deleteorder(row.orderId)}>
                  <CButton
                    color="danger"
                    title="Delete Order "
                    // onClick={()=>getorderDataForEdit(row.customerId)}
                    variant="outline"
                    // onClick={() => deleteorder(row.orderId)}
                    className="px-0 buttonsOrderPage "
                  >
                    <CIcon icon={cilTrash} size="lg" />
                  </CButton>
                  <span className="ms-3">Delete</span>

                  {/* <span  className="ms-3"  onClick={() => deleteorder(row.orderId)}> <CIcon icon={cilTrash} size="lg" /> Delete</span> */}
                </CDropdownItem>

              </CDropdownMenu>
            </CDropdown>
          </div>
        </>
      ),
    },
    {
      name: "Order No",
      selector: (row) => row.orderId,
      width: "85px"
    },
    {
      name: "Customer Name",
      wrap: true,
      selector: (row) =>
        row.customerName + " (Ph. " + row.customerPhone + ")"
      ,
    },
    // {
    //   name: "Customer Phone",
    //   selector: (row) => row.customerPhone,
    //   width: "125px"
    // },
    {
      name: "Complete",
      selector: (row) =>
      (
        <>
          {row.isComplete == "1" ? (
            <>
              <span
                className="badge p-2"
                style={{
                  backgroundColor: "lightgreen",
                  color: "#006400",
                  fontSize: "0.75rem",
                }}
              >
                Yes
              </span>
            </>
          ) : (
            <span
              className="badge p-2"
              style={{
                backgroundColor: "lightpink",
                color: "red",
                fontSize: "0.75rem",
              }}
            >
              No
            </span>
          )}
          &nbsp;
        </>
      ),
      width: "90px"
    },
    {
      name: "Sent",
      selector: (row) =>
      (
        <>
          {row.isSent == "1" ? (
            <>
              <span
                className="badge p-2"
                style={{
                  backgroundColor: "lightgreen",
                  color: "#006400",
                  fontSize: "0.75rem",
                }}
              >
                Yes
              </span>
            </>
          ) : (
            <span
              className="badge p-2"
              style={{
                backgroundColor: "lightpink",
                color: "red",
                fontSize: "0.75rem",
              }}
            >
              No
            </span>
          )}
          &nbsp;
        </>
      ),
      width: "90px"
    },
    {
      name: "Bill paid",
      selector: (row) => (
        <>
          {
            row.isBillPaid === "1" ? (
              <>
                <span
                  className="badge p-2"
                  style={{
                    backgroundColor: "lightgreen",
                    color: "#006400",
                    fontSize: "0.75rem",
                  }}
                >
                  Yes
                </span>
              </>
            ) : (
              <>
                <span
                  className="badge p-2"
                  style={{
                    backgroundColor: "lightpink",
                    color: "red",
                    fontSize: "0.75rem",
                  }}
                >
                  No
                </span>
              </>
            )
          }
        </>
      ),
      width: "90px"
    },
    {
      name: "Order Date",
      wrap: true,
      selector: (row) => row.orderDate,
      width: "105px"
    },
    {
      name: "Total Charges",
      selector: (row) => row.totalCharges,
      width: "110px"
    },
    // {
    //   name: "Outstanding",
    //   selector: (row) => {
    //     return (
    //       <div className="button-customerorder">
    //         <CButton
    //           color="success"
    //           variant="outline"
    //           title="Add Outstanding"
    //           className="ms-2 buttonsOrderPage w-100 "
    //           onClick={() =>
    //             navigate("/customerbilling/actionoutstanding", {
    //               state: {
    //                 orderId: row.orderId,
    //                 orderDate: row.orderDate,
    //                 customerId: row.customerId,
    //                 customerName: row.customerName,
    //               },
    //             })
    //           }
    //         >
    //           <CIcon icon={cilLibraryAdd} size="lg" />
    //         </CButton>
    //       </div>
    //     );
    //   },
    //   width: "110px"
    // },
    // {
    //   name: "SMS Sent?",
    //   wrap: false,
    //   selector: (row) => (
    //     <>
    //       {row.smsSendFlag !== "0" ? (
    //         <>
    //           <h6
    //             className="badge p-2"
    //             style={{
    //               backgroundColor: "lightgreen",
    //               color: "#006400",
    //               fontSize: "0.7rem",
    //             }}
    //           >
    //             Yes
    //           </h6>
    //         </>
    //       ) : (
    //         <span
    //           className="badge p-2"
    //           style={{
    //             backgroundColor: "lightpink",
    //             color: "red",
    //             fontSize: "0.7rem",
    //           }}
    //         >
    //           No
    //         </span>
    //       )}
    //       &nbsp;
    //     </>
    //   ),
    //   width: "100px"
    // },
  ];

  const handleClear = (e) => {
    let currentDate = new Date();
    let endformattedDate = currentDate.toLocaleDateString("es-CL", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
    let startDate = new Date(currentDate);
    startDate.setDate(currentDate.getDate() - 1);
    let startformattedDate = startDate.toLocaleDateString("es-CL", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });

    setfilterStartDate(startformattedDate)
    orderListApi(
      userData.userinfo.userId,
      0,
      startformattedDate,
      startformattedDate,
      "",
      ""
    )
      .then((res) => {
        setorderList(res.data);
      })
    setSelectedCustomer("");
    setSelectedSentType("");
    sessionStorage.removeItem("selectedCustomer-yesterday");
    sessionStorage.removeItem("selectedSentType-yesterday");
  };
  return (
    <div>
      <CHeaderDivider />
      <CContainer fluid>
        <CRow>
          <CCol xs={12}>
            <h5 className="main-title" style={{ marginLeft: "-6px" }}> Order </h5>
          </CCol>
          <CCol xs={8}>
            <CBreadcrumb
              className="m-0 ms-2"
              style={{ "--cui-breadcrumb-divider": "'>'" }}
            >
              <CBreadcrumbItem style={{ marginLeft: "-12px" }}>
                <Link to="/dashboard">Home</Link>
              </CBreadcrumbItem>
              <CBreadcrumbItem actives>Yesterday's Orders</CBreadcrumbItem>
            </CBreadcrumb>
          </CCol>
          <CCol xs={4}>
            <div className="text-end">
              {/* <Link to="/customer"><CButton color="warning"  size="sm" className="px-4 text-end text-white" type="button" style={{marginTop: "-52px"}}>
             Back
              </CButton></Link> */}
              <Link to="/order">
                <CButton
                  color="info"
                  size="sm"
                  className="text-end text-white"
                  type="button"
                  style={{ marginTop: "-52px" }}
                >
                  + Order
                </CButton>
              </Link>
            </div>
          </CCol>
        </CRow>
      </CContainer>
      <br />

      <CRow className="justify-content-center">
        <CCol md={12} lg={12}>
          <CCard>
            <CCardHeader>
              <b> Date </b> :  {filterStartDate}
            </CCardHeader>
            <CCardBody>
              <CRow className="justify-content-start">

                <CCol md={4} sm={12} >
                  <CFormLabel>Customer Name</CFormLabel>
                  <Select
                    options={customerList}
                    placeholder={
                      <div className="select-placeholder-text">
                        Select Customer
                      </div>
                    }
                    className="text-start mb-3"
                    value={customerList?.find(
                      (obj) => obj.value === selectedCustomer
                    ) || ""}
                    onChange={handleCustomer}
                    onInputChange={handleInputChange}
                  />
                </CCol>
                <CCol md={3}>
                  <CFormLabel>Sent</CFormLabel>
                  <Select
                    options={sentOptions}
                    placeholder={
                      <div className="select-placeholder-text">
                        Select Options
                      </div>
                    }
                    className="text-start mb-3"
                    value={sentOptions?.find(
                      (obj) => obj.value === selectedSentType
                    ) || ""}
                    onChange={handleSent}
                  />
                </CCol>

                <CCol xs={12}>
                  <div className="text-center ">
                    <CButton
                      color="success"
                      className="m-2 text-white"
                      onClick={() => {
                        getorderlist(0);
                      }}
                      type="button"
                    >
                      Search
                    </CButton>
                    <CButton
                      color="dark"
                      type="submit"
                      className="text-white"
                      onClick={() => {
                        handleClear();
                      }}
                    >
                      Clear
                    </CButton>
                  </div>
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
          <CModal
            alignment="center"
            scrollable
            visible={visible}
            onClose={() => {
              setVisible(false);
              setOrderIdForPhoto("");
              setImage("");
              setImageError("");
            }}
          >
            <CModalHeader>
              <CModalTitle>Order Add Image</CModalTitle>
            </CModalHeader>
            <CModalBody>
              <CContainer>
                <CRow className="mt-3">
                  <CCol md={7} sm={12} xs={12}>
                    <CFormLabel htmlFor="validationCustom02">Image</CFormLabel>
                    <CFormInput
                      name="image"
                      type="file"
                      id="validationTextarea"
                      aria-label="file example"
                      onChange={handleFileChangeImage}
                    />
                  </CCol>
                  <CCol xs={12} className="mt-3">
                    {preview ? (
                      <>
                        <img src={preview} className="imagePreview" />
                      </>
                    ) : (
                      <></>
                    )}
                  </CCol>
                  <span className="text-danger">{imageError}</span>
                  <button
                    className="btn btn-success mt-3"
                    onClick={(e) => {
                      uploadPhoto();
                    }}
                  >
                    Upload Photo
                  </button>
                </CRow>
              </CContainer>
            </CModalBody>
          </CModal>
          <br />
          <CCard>
            <CCardBody className="table-responsive">
              <DataTable
                className="tableTopSpace "
                columns={columns}
                responsive={true}
                data={orderList}
                progressPending={listLoading}
                paginationTotalRows={orderTotalRecord}
                onChangePage={handlePageChange}
                pagination
                paginationComponentOptions={paginationComponentOptions}
                customStyles={customStyles}
                paginationServer
              />
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </div>
  );
}

export default Yesterdaysorders;